import React, { useState, useEffect } from "react"
import { MapContainer, TileLayer, Marker, ZoomControl } from "react-leaflet"
import styled from "styled-components"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import Pin from "../../assets/images/pin.png"
import LightPin from "../../assets/images/light_pin.png"

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: ${props => (props.visible ? "block" : "none")};
  margin-top: -53px;

  @media only screen and (max-width: 480px) {
    margin-top: -53px;
  }
`

export const BoothMap = ({
  onClick,
  visible,
  boothDetails,
  center,
  selectedBoothName,
}) => {
  const [map, setMap] = useState(null)
  const BoothMarker = ({ booth, selected }) => {
    const BoothIcon = new L.icon({
      iconUrl: selected ? Pin : LightPin,
      iconSize: selected ? [50, 90] : [35, 65],
    })

    return (
      <Marker
        position={booth.location}
        icon={BoothIcon}
        eventHandlers={{
          click: e => {
            handleOnClick(booth)
          },
        }}
      ></Marker>
    )
  }

  const handleOnClick = data => {
    onClick(data)
  }

  useEffect(() => {
    if (map !== null && map) {
      // var center = map.project(center)
      map.setView(center[0], center[1], map.getZoom(), {
        animate: true,
      })
    }
  }, [center, map])

  if (typeof window !== undefined) {
    return (
      <Container visible={visible}>
        <MapContainer
          center={center[0]}
          zoom={12}
          scrollWheelZoom={true}
          doubleClickZoom={false}
          style={{ height: "100vh" }}
          zoomControl={false}
          whenCreated={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            subdomains="abcd"
            maxZoom={19}
          />
          {boothDetails &&
            selectedBoothName &&
            boothDetails.map(booth => (
              <BoothMarker
                booth={booth}
                key={booth.boothName}
                selected={selectedBoothName === booth.boothName}
              />
            ))}
          <ZoomControl position="bottomleft" />
        </MapContainer>
      </Container>
    )
  }
  return null
}
