import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 0.5px solid white;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  padding: 13px 0px;
`

const Header = styled.p`
  text-align: start;
  font-size: 12px;
  color: white;
  margin: 0px;
  margin-top: -3px;
  font-weight: 500;
  font-family: Futura;
  /* font-family: "Suisse Intl"; */
`

const Detail = styled.p`
  text-align: end;
  font-size: 20px;
  color: white;
  height: 24px;
  margin: -15px 0px 0px 0px;
  font-weight: 300;
`

const DetailLink = styled.a`
  text-align: end;
  font-size: 20px;
  color: white;
  height: 24px;
  margin: -15px 0px 0px 0px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
`

export const BoothContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0px;
  @media only screen and (max-width: 850px) {
    padding: 10px 24px 15px 24px;
  }
`

export const BoothDetail = ({ header, detail, detailLink }) => {
  return (
    <>
      <Container>
        <Header>{header}</Header>
        {detailLink !== "" && header === "DETAILS" ? (
          <DetailLink href={detailLink} target="_blank">
            {detail}
          </DetailLink>
        ) : (
          <Detail>{detail}</Detail>
        )}
      </Container>
    </>
  )
}
