import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UpButton from "../../assets/locations/upbutton.svg";
import DownButton from "../../assets/locations/downbutton.svg";

const Container = styled.div`
  width: 320px;
  /* height: 40px; */
  position: fixed;
  top: 93px;
  left: 80px;
  z-index: 9999;

  @media only screen and (max-width: 850px) {
    top: 93px;
    left: 39px;
  }
  @media only screen and (max-width: 480px) {
    left: 16px;
    height: 39px;
    width: 200px;
    top: 70px;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 250px;
  height: ${props => (props.toggle ? "fit-content" : "40px")};
  padding: 0px 46px 0px 16px;
  padding-bottom: ${props => (props.toggle ? "10px" : "0px")};
  text-align: left;
  min-height: 1px;
  text-align: left;
  border: none;
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.25));
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
  border: 0.5px solid #000000;
  overflow: hidden;
  @media only screen and (max-width: 480px) {
  }
`;

const Control = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0;
  min-height: 1px;
  height: 40px;
  top: 0px;
  cursor: pointer;
  flex-wrap: wrap;
  position: relative;
  @media only screen and (max-width: 480px) {
  }
`;

const ValueContainer = styled.div`
  height: 100%;
  padding: 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

const SingleValue = styled.div`
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  min-height: 1px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Futura Condensed";
`;

const IndicatorContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  align-items: center;
  margin-right: -30px;
`;

const Indicator = styled.img`
  width: 20px;
  height: 20px;
`;

const Menu = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const MenuListKey = styled.div`
  width: 100%;
  height: 34px;
  font-size: 16px;
  padding-top: 10px;
  margin: 0px 0px 2px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  border-top: 0.5px solid black;
  cursor: default;
`;

const MenuListValue = styled.div`
  width: 100%;
  height: 24px;
  font-size: 16px;
  display: flex;
  margin: 2px 0px;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid black;
  margin-right: 8px;
  margin-left: 16px;
  background-color: ${props => (props.selected ? "black" : "white")};
`;

export const NewDropdown = ({
  options,
  selectDropdown,
  selectedItem,
  setSelectedItem
}) => {
  const [editedOptions, setEditedOptions] = useState();
  const [toggle, setToggle] = useState(false);

  const handleSelect = data => {
    setSelectedItem(data);
    selectDropdown(data);
  };

  useEffect(() => {
    let tempOptions = {};
    options.forEach(boothInfo => {
      let province = boothInfo.detail.province;
      let boothName = boothInfo.detail.boothName;
      if (province) {
        if (tempOptions[province]) {
          tempOptions[province][boothName] = {
            label: boothName,
            returnValue: boothInfo
          };
        } else {
          tempOptions[province] = {};
          tempOptions[province][boothName] = {
            label: boothName,
            returnValue: boothInfo
          };
        }
      } else {
        tempOptions["OTHER"] = {};
        tempOptions["OTHER"][boothName] = {
          label: boothName,
          returnValue: boothInfo
        };
      }
    });
    setEditedOptions(tempOptions);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Container>
        <DropdownContainer
          toggle={toggle}
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <Control>
            <ValueContainer>
              <SingleValue>
                {selectedItem ? selectedItem.label : "SELECT LOCATION"}
              </SingleValue>
            </ValueContainer>
            <IndicatorContainer>
              <Indicator src={toggle ? UpButton : DownButton} />
            </IndicatorContainer>
          </Control>
          <Menu>
            {editedOptions &&
              Object.keys(editedOptions).map(province => (
                <>
                  <MenuListKey>{province}</MenuListKey>
                  {Object.keys(editedOptions[province]).map(boothName => (
                    <>
                      <MenuListValue
                        onClick={() =>
                          handleSelect(
                            editedOptions[province][boothName]["returnValue"]
                          )
                        }
                      >
                        <Dot
                          selected={
                            selectedItem &&
                            selectedItem.detail.boothName === boothName
                          }
                        />
                        {boothName}
                      </MenuListValue>
                    </>
                  ))}
                </>
              ))}
          </Menu>
        </DropdownContainer>
      </Container>
    </>
  );
};
