import styled from "styled-components";
import LidoSamplePic1 from "../../assets/images/ployh.png";
import LidoSamplePic2 from "../../assets/images/lidosamplepic2.png";
import { animated } from "react-spring";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 53px;
`;

export const ContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    width: 100%;
    height: 100%;
  }
`;

export const PlaceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  padding: 0px 0px 0px 0px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 0px 32px;
  }
`;

export const PlaceContainerMobile = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  padding: 0px 0px 0px 0px;
  @media only screen and (max-width: 850px) {
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 24px 0px 24px;
  }
`;

export const CampaignContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  padding: 0px 0px 0px 0px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PlaceProvinceDesktop = styled.p`
  font-size: 20px;
  color: white;
  margin: 0px 0px 5px 0px;
  font-weight: 300;
  width: 100%;
  padding-bottom: 0px;
  height: 17px;
  @media only screen and (max-width: 480px) {
    font-size: 20px;
    padding-bottom: 20px;
  }
`;

export const PlaceProvince = styled.p`
  font-size: 20px;
  color: white;
  margin: 0px 0px 14px 0px;
  font-weight: 300;
  width: 100%;
  padding-bottom: 0px;
  height: 17px;
  @media only screen and (max-width: 480px) {
    font-size: 20px;
    padding-top: 6px;
    padding-left: 5px;
  }
`;

export const PlaceName = styled.p`
  font-size: 32px;
  min-height: 28px;
  line-height: 0.9;
  color: white;
  margin: 0px;
  font-weight: 300;
  margin-right: 5px;
  flex-shrink: 0;
  @media only screen and (max-width: 480px) {
    font-size: 32px;
    margin: 0px 0px 5px 0px;
  }
`;

export const CloseButton = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 32px;
  top: 25px;
  z-index: 999999;
`;

export const PCPlaceContainer = styled.div`
  position: absolute;
  width: 505px;
  height: 676px;
  bottom: calc((100vh - 55px - 676px) / 2);
  right: 48px;
  padding: 32px 32px 0px 32px;
  border-radius: 10px;
  background-color: ${props => props.bgcolor};
  z-index: 99999;
  overflow: scroll;
  @media only screen and (max-width: 1200px) {
    width: 424px;
    height: 594px;
    right: 16px;
    bottom: calc((100vh - 55px - 600px) / 2);
  }
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

export const MockData = [
  {
    boothName: "LIDO",
    province: "Bangkok",
    district: "Sukhumvit 31",
    imagePosition: { x: 33.3726, y: 47.2348 },
    imageWidth: 100,
    location: [13.7329563, 100.5310365],
    imageRotation: -3,
    detail: [
      { header: "PERMANENT BOOTH", detail: "10 AM - 8 PM " },
      { header: "PRICE", detail: "100.00 THB" },
      { header: "DETAILS", detail: "3 acts, 2 copies" }
    ],
    sampleImages: [LidoSamplePic1, LidoSamplePic2],
    bgColor: "#6B9ED7",
    textColor: "#ffffff",
    details: "3 acts, 2 copies",
    openPeriod: "10 AM - 8 PM",
    type: "Permanent",
    price: "100"
  },
  {
    boothName: "FICS",
    province: "Bangkok",
    district: "Sukhumvit 31",
    imagePosition: { x: 208.194, y: 232.889 },
    imageWidth: 100,
    location: [13.737276, 100.5649705],
    imageRotation: 5,
    detail: [
      { header: "PERMANENT BOOTH", detail: "10 AM - 8 PM " },
      { header: "PRICE", detail: "100.00 THB" },
      { header: "DETAILS", detail: "3 acts, 2 copies" }
    ],
    sampleImages: [LidoSamplePic1, LidoSamplePic2],
    bgColor: "#CA3617",
    textColor: "#ffffff",
    details: "3 acts 2 copies",
    openPeriod: "10 AM - 8 PM",
    type: "Permanent",
    price: "100 THB"
  },
  {
    boothName: "WWA x CHOOSELESS",
    province: "Bangkok",
    district: "Sukhumvit 31",
    imagePosition: { x: 70.0525, y: 434.348 },
    imageWidth: 100,
    location: [13.7391699, 100.58601],
    imageRotation: 5,
    detail: [
      { header: "PERMANENT BOOTH", detail: "10 AM - 8 PM " },
      { header: "PRICE", detail: "100.00 THB" },
      { header: "DETAILS", detail: "3 acts, 2 copies" }
    ],
    sampleImages: [LidoSamplePic1, LidoSamplePic2],
    bgColor: "#1275DB",
    textColor: "#ffffff",
    details: "3 acts 2 copies",
    openPeriod: "10 AM - 8 PM",
    type: "Permanent",
    price: "100"
  },
  {
    boothName: "BONCI",
    province: "Bangkok",
    district: "Sukhumvit 31",
    imagePosition: { x: 70.0525, y: 434.348 },
    imageWidth: 100,
    location: [13.7908261, 100.5462724],
    imageRotation: 5,
    detail: [
      { header: "PERMANENT BOOTH", detail: "10 AM - 8 PM " },
      { header: "PRICE", detail: "100.00 THB" },
      { header: "DETAILS", detail: "3 acts, 2 copies" }
    ],
    sampleImages: [LidoSamplePic1, LidoSamplePic2],
    bgColor: "#d8662f",
    textColor: "#ffffff",
    details: "3 acts 2 copies",
    openPeriod: "10 AM - 8 PM",
    type: "Permanent",
    price: "100 THB"
  }
];

export const BottomSheet = styled(animated.div)`
  display: none;
  background-color: ${props => props.bgColor};
  @media only screen and (max-width: 850px) {
    display: block;
    height: 80px;
    width: 100vw;
    border-radius: 30px 30px 0px 0px;
    position: fixed;
    bottom: 0px;
    z-index: 99999;
  }
  @media only screen and (max-width: 480px) {
    display: block;
    height: 80px;
    width: 100vw;
    border-radius: 30px 30px 0px 0px;
    position: fixed !important;
    bottom: 0px;
    z-index: 99999;
  }
`;

export const Line = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: white;
`;
