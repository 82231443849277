import React, { useState, useEffect, useRef } from "react";
import { graphql, StaticQuery } from "gatsby";
import Sheet from "react-modal-sheet";
import { MaterialCarousel } from "../components/MaterialCarousel";
import { useSpring } from "react-spring";
import getFirebase from "../Firebase/firebase";

import DownButton from "../assets/icon/downbutton.svg";
import UpButton from "../assets/icon/upbutton.svg";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  BoothContainer,
  BoothDetail
} from "../components/location/BoothDetail";
import { OtherLocations } from "../components/OtherLocations/OtherLocations";
import {
  Container,
  PlaceContainer,
  PlaceContainerMobile,
  PlaceName,
  PlaceProvince,
  PlaceProvinceDesktop,
  CloseButton,
  PCPlaceContainer,
  BottomSheet,
  Line,
  MockData
} from "../components/PlaceDetail/PlaceDetail";
import { BoothMap } from "../components/BoothMap/BoothMap";
import { NewDropdown } from "../components/Dropdown/NewDropdown";

import "../utils/normalize.css";
import "../utils/css/screen.css";

const LocationPage = ({ data }, location) => {
  let firebase = useRef();
  const siteTitle = data.site.siteMetadata.title;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState();
  const [markerClicked, setMarkerClicked] = useState(false);
  const [boothData, setBoothData] = useState();
  const [selectedBooth, setSelectedBooth] = useState();
  const [boothClicked, setBoothClicked] = useState(false);
  const [center, setCenter] = useState([[13.7348976, 100.5168928], 12]);
  const springStyles = useSpring({ bottom: open ? -80 : 0 });
  const [isMobile, setIsMobile] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const handleOpen = data => {
    setCenter([data.location, 13]);
    setSelectedBooth(data);
    let tempDropdown = {
      detail: data,
      label: data.province + " | " + data.boothName,
      value: data.location
    };
    setSelectedItem(tempDropdown);
    if (!boothClicked) {
      setOpen(true);
      setMarkerClicked(true);
      setTimeout(() => setBoothClicked(true), 500);
    }
  };

  const handleCloseSheet = () => {
    if (markerClicked && boothClicked) {
      setOpen(false);
      setMarkerClicked(false);
      setBoothClicked(false);
    }
  };

  const handleCloseButton = () => {
    setOpen(false);
    setMarkerClicked(false);
    setTimeout(() => setBoothClicked(false), 500);
  };

  const selectDropdown = data => {
    setCenter([data.value, 13]);
    setSelectedBooth(data.detail);
    setSelectedItem(data);
    if (!boothClicked) {
      setOpen(true);
      setMarkerClicked(true);
      setTimeout(() => setBoothClicked(true), 500);
    }
  };

  const getAndSetBoothData = () => {
    firebase.current
      .firestore()
      .collection("locations")
      .get()
      .then(snap => {
        let data = {};
        let inputOption = [];
        snap.forEach(doc => {
          data[doc.id] = doc.data();
        });
        let boothData = Object.keys(data)
          .map(boothId => {
            return { ...data[boothId], id: boothId };
          })
          .filter(boothData => boothData.published);
        snap.forEach(doc => {
          let tempData = doc.data();
          inputOption.push({
            value: tempData.location,
            label: `${tempData.province} | ${tempData.boothName}`,
            detail: tempData
          });
        });
        setOptions(inputOption);
        setBoothData(boothData);
        setSelectedBooth(boothData[0]);
      })
      .catch(() => {
        setOptions(
          MockData.map(item => ({
            value: item.location,
            label: `${item.province} | ${item.boothName}`,
            detail: item
          }))
        );
        setBoothData(MockData);
        setSelectedBooth(MockData[0]);
      });
  };

  const handleResize = () => {
    if (window.innerWidth <= 850) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!getFirebase()) return;
    firebase.current = getFirebase();
    getAndSetBoothData();
    //eslint-disable-next-line
  }, [firebase]);

  return (
    <>
      <Layout title={siteTitle}>
        <SEO
          title="Locations"
          keywords={[`location`, `sculpture`, `อยู่ที่ไหน`, `ตู้sculpture`]}
        />
        <Container>
          <BoothMap
            onClick={selectedData => handleOpen(selectedData)}
            visible={true}
            boothClicked={boothClicked}
            boothDetails={boothData}
            center={center}
            selectedBoothName={selectedBooth && selectedBooth["boothName"]}
          />
          {selectedBooth && (
            <PCPlaceContainer bgcolor={selectedBooth.bgColor}>
              <PlaceContainer>
                <PlaceName>{selectedBooth.boothName}</PlaceName>
                <PlaceProvinceDesktop>
                  {selectedBooth.district + ", " + selectedBooth.province}
                </PlaceProvinceDesktop>
              </PlaceContainer>
              {selectedBooth && selectedBooth && (
                <BoothContainer>
                  <BoothDetail
                    detail={selectedBooth.openPeriod}
                    header={selectedBooth.boothType.toUpperCase() + " BOOTH"}
                  />
                  <BoothDetail
                    detail={
                      selectedBooth.price
                        ? selectedBooth.price.includes("THB")
                          ? selectedBooth.price
                          : selectedBooth.price + " THB"
                        : "Please contact thisissculpture@gmail.com"
                    }
                    header={"PRICE"}
                  />
                  <BoothDetail
                    detail={selectedBooth.details}
                    detailLink={selectedBooth.detailsLink}
                    header={"DETAILS"}
                  />
                  <MaterialCarousel
                    sampleImages={selectedBooth.sampleImages}
                    margin={"32px -7px 32px -7px"}
                  />
                  {boothData && (
                    <OtherLocations
                      otherLocations={boothData}
                      handleOpen={booth => handleOpen(booth)}
                      color={"white"}
                      location={selectedBooth.location}
                      mapUrl={selectedBooth.mapUrl}
                    />
                  )}
                </BoothContainer>
              )}
            </PCPlaceContainer>
          )}
          {selectedBooth && isMobile && (
            <Sheet isOpen={open} onClose={() => handleCloseSheet(false)}>
              <Sheet.Container
                style={{
                  height: "514px",
                  backgroundColor: selectedBooth.bgColor,
                  borderRadius: "24px 30px 0px 0px",
                  overflow: "scroll"
                }}
              >
                <Sheet.Header>
                  <CloseButton
                    src={DownButton}
                    onClick={() => setOpen(false)}
                    onTouchStart={() => handleCloseSheet(false)}
                  />
                  {selectedBooth && selectedBooth && (
                    <PlaceContainerMobile>
                      <PlaceName>{selectedBooth.boothName}</PlaceName>
                      <PlaceProvince>{selectedBooth.province}</PlaceProvince>
                    </PlaceContainerMobile>
                  )}
                </Sheet.Header>
                <Sheet.Content disableDrag>
                  {selectedBooth && selectedBooth && (
                    <BoothContainer>
                      <BoothDetail
                        detail={selectedBooth.openPeriod}
                        header={
                          selectedBooth.boothType.toUpperCase() + " BOOTH"
                        }
                      />
                      <BoothDetail
                        detail={
                          selectedBooth.price
                            ? selectedBooth.price.includes("THB")
                              ? selectedBooth.price
                              : selectedBooth.price + " THB"
                            : "Please contact thisissculpture@gmail.com"
                        }
                        header={"PRICE"}
                      />
                      <BoothDetail
                        detail={selectedBooth.details}
                        detailLink={selectedBooth.detailsLink}
                        header={"DETAILS"}
                      />
                      <MaterialCarousel
                        sampleImages={selectedBooth.sampleImages}
                      />
                      {boothData && (
                        <OtherLocations
                          otherLocations={boothData}
                          handleOpen={booth => handleOpen(booth)}
                          color={"white"}
                          location={selectedBooth.location}
                          mapUrl={selectedBooth.mapUrl}
                        />
                      )}
                    </BoothContainer>
                  )}
                </Sheet.Content>
              </Sheet.Container>

              <Sheet.Backdrop onClick={() => handleCloseSheet(false)} />
            </Sheet>
          )}
        </Container>
      </Layout>
      {options && (
        <NewDropdown
          options={options}
          selectDropdown={selectDropdown}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
      {selectedBooth && (
        <BottomSheet style={springStyles} bgColor={selectedBooth.bgColor}>
          {selectedBooth && selectedBooth && (
            <>
              <PlaceContainerMobile>
                <PlaceName>{selectedBooth.boothName}</PlaceName>
                <PlaceProvince>{selectedBooth.province}</PlaceProvince>
                <CloseButton
                  src={UpButton}
                  onClick={() => setOpen(true)}
                  onTouchStart={() => handleCloseButton(false)}
                />
              </PlaceContainerMobile>
              <PlaceContainerMobile style={{ paddingTop: "8px" }}>
                <Line />
              </PlaceContainerMobile>
            </>
          )}
        </BottomSheet>
      )}
    </>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <LocationPage location={props.location} data={data} {...props} />
    )}
  />
);
