import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Container = styled.div`
  width: 100%;
  padding-top: 15px;
`;
const Map = styled.p`
  font-size: 20px;
  text-decoration: underline;
  color: ${props => (props.color ? props.color : "white")};
  font-weight: 300;
  margin: 0px 0px 16px 0px;
  cursor: pointer;
  height: 14px;
  width: 100%;
  text-align: center;
  &:nth-last-child() {
    margin-bottom: 0px !important;
  }

  @media only screen and (max-width: 480px) {
    font-size: 20px;
    margin: 2px 0px 17px 0px;
  }
`;

const Line = styled.div`
  width: calc(100% + 64px);
  height: 0.5px;
  margin-left: -32px;
  background-color: white;
  @media only screen and (max-width: 840px) {
    width: calc(100% + 48px);
    margin-left: -24px;
  }
`;

export const OtherLocations = ({
  otherLocations,
  handleOpen,
  color,
  location,
  mapUrl
}) => {
  return (
    <>
      <Line />
      <Container>
        <Map color={color ? color : "black"}>
          <Link
            href={
              mapUrl
                ? mapUrl
                : `https://maps.google.com/?q=${location[0]},${location[1]}`
            }
            target="_blank"
          >
            View location on map
          </Link>
        </Map>
      </Container>
    </>
  );
};
